// these keys are not so secret. they are used for getting location and timezone info
// but they have limited amount of request per day, so they are made different in dev and prod.
const devGoogleApiKey = 'AIzaSyBQNnaDDmnRPsgyC2aLq5-iL67d2mufleg';
const prodGoogleApiKey = 'AIzaSyB9y3dfve4ifpgWX6lyfX7_7E4Q4eSo9S0';
const prodSentryDSN = 'https://ab6d14a252fd3ca3c54e098c27e7e029@us.sentry.io/4506508775587840';
const devSentryDSN = 'https://0cbf2df82cd82e0a28a0cd0f2d9d3a8d@us.sentry.io/4506508774146048';
const devStripePublishableKey = 'pk_test_cNrodxeVF0Czd6vuxSTRn3dJ';
const prodStripePublishableKey = 'pk_live_SN5byN5OqHvUGLHR2nbB6cjn';
const googleApiKey = process.env.GM_ENV === 'production' ? prodGoogleApiKey : devGoogleApiKey;
const sentryDSN = process.env.GM_ENV === 'production' ? prodSentryDSN : devSentryDSN;
const stripePublishableKey =
  process.env.GM_ENV === 'production' ? prodStripePublishableKey : devStripePublishableKey;

// Release Tag Generation
const today = new Date();
const formattedDate = today.toISOString().slice(0, 10);
const patientPortalTag = `${process.env.GM_ENV}: ${formattedDate}`;

// TODO: It should be from CI/Cd variables, follow up ticket required to CI/CD changes
const AUTH0_CONFIG = {
  CLIENT_ID: '1CJjVdBGA6QYrmmFouY6Orqa65As33SO',
  DOMAIN: 'genomemedical-dev.us.auth0.com',
  AUDIENCE: 'sbe-api',
};

const auth0EnabledByEnv = {
  development: true,
  qa: false,
  staging: false,
  production: false,
};

let GLOBALS = {
  __DEV__: false,
  'process.env.GM_ENV': JSON.stringify(process.env.GM_ENV),
  'process.env.GOOGLE_API_KEY': JSON.stringify(googleApiKey),
  'process.env.REACT_APP_SENTRY_DSN': JSON.stringify(sentryDSN),
  'process.env.REACT_APP_PATIENT_PORTAL_TAG': JSON.stringify(patientPortalTag),
  'process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY': JSON.stringify(stripePublishableKey),
  'process.env.REACT_APP_AUTH0_CLIENT_ID': JSON.stringify(AUTH0_CONFIG.CLIENT_ID),
  'process.env.REACT_APP_AUTH0_DOMAIN': JSON.stringify(AUTH0_CONFIG.DOMAIN),
  'process.env.REACT_APP_AUTH0_AUDIENCE': JSON.stringify(AUTH0_CONFIG.AUDIENCE),
  'process.env.REACT_APP_FEATURE_FLAG_ENABLE_AUTH0_LOGIN':
    auth0EnabledByEnv[process.env.GM_ENV] === true,
};

export default GLOBALS;
