import React, { useEffect, useState } from 'react';
import commonScheduling, { STAGE_SPLASH_SCREEN, STAGE_AUTH0 } from 'services/wizardWorkflows';
import './RegistrationWizardPage.scss';
import authService from 'services/api/authService';
import _ from 'lodash';
import Auth0SignupPage from './Auth0SignupPage';
import SchedulingIntroPage from './SchedulingIntroPage';

const RegistrationWizardPage = () => {
  const [activeStage, setActiveStage] = useState(STAGE_SPLASH_SCREEN);

  useEffect(() => {
    const service = authService.getPreselectedService();
    if (!service) {
      setActiveStage(STAGE_AUTH0);
    }
  }, []);

  return (
    <div className="scheduling-page full-height no-footer">
      {activeStage == STAGE_SPLASH_SCREEN && (
        <SchedulingIntroPage setActiveStage={() => setActiveStage(STAGE_AUTH0)} hasAuth0={true} />
      )}
      {activeStage == STAGE_AUTH0 && <Auth0SignupPage />}
    </div>
  );
};

export default RegistrationWizardPage;
