import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../services/api/api';
import { logout } from '../ducks/auth/email';

const AuthProvider = ({ children, legacyLogout, requestLogout }) => {
  const { logout: auth0Logout } = useAuth0();

  useEffect(() => {
    if (requestLogout == true) {
      handleLogout();
    }
  }, [requestLogout]);

  const handleLogout = () => {
    if (api.hasAuth0Token()) {
      return auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      }).then(() => {
        return legacyLogout();
      });
    } else {
      return legacyLogout();
    }
  };

  return <div>{children}</div>;
};

export default connect(
  (state) => {
    return {
      requestLogout: state.user.requestLogout,
    };
  },
  (dispatch) => {
    const legacyLogout = () => dispatch(logout());
    return {
      legacyLogout,
    };
  }
)(AuthProvider);
