import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';

import api from 'services/api/api';
import { AUTH0_CONFIG } from '../../../Auth0Wrapper';

const Auth0SignupPage = (props) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      async function getAccessToken() {
        try {
          const at = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUTH0_CONFIG.audience,
            },
          });
        } catch (e) {
          console.error(e);
          setError(e);
        }
      }
      void getAccessToken();
    } else {
      try {
        void loginWithRedirect({
          authorizationParams: {
            connection: 'email',
            redirect_uri: window.location.origin,
          },
        });
      } catch (_) {}
    }
  }, [isAuthenticated, isLoading]);

  return error && <div>{error}</div>;
};

export default Auth0SignupPage;
